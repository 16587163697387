<template>
  <q-page class="flex flex-center">
    <p class="text-faded">Nests</p>
  </q-page>
</template>

<script>
export default {
  name: 'PageNests',
  data () {
    return {
      uuid: 'cc62df02-f34a-4afa-9ce9-579631e2ff95'
    }
  },
  methods: {
    encodeUUID (uuid) {
      // Remove hyphens and convert to binary
      const hex = uuid.replace(/-/g, '')
      const bin = Buffer.from(hex, 'hex')
      // Convert binary to Base64
      return bin.toString('base64').replace(/=/g, '')
    },
    decodeUUID (uuidEncoded) {
      // Decode from Base64
      const bin = Buffer.from(uuidEncoded, 'base64')
      // Convert binary to hex
      let hex = bin.toString('hex')
      // Re-insert hyphens to get the UUID in standard format
      return hex.substring(0, 8) + '-' + hex.substring(8, 12) + '-' + hex.substring(12, 16) + '-' + hex.substring(16, 20) + '-' + hex.substring(20)
    }
  }
}
</script>
